import React from "react";
import './ShareAppLink.scss';
// import txtLogo from '../../assets/images/damnTxt.png'
// import logo from '../../assets/images/logo_yellow.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isAndroid,
    isIOS
} from "react-device-detect";
import googleappstore from '../../assets/images/Google_Play_Store.png'
import appstore from '../../assets/images/App_Store.png'
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png"

const AppLink = (props) => {
    let club_name;
    let ios_url;
    let android_url;
    let data
    if (isBrowser === true) {
        const project = () => {
            switch (props.match.params.clubidentifier) {

                case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-rovers-fc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };
                case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                case "tranentcolts-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/lka0ibxfpc0pd3ripugg.png" };
                case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };

                case "workington-afc": return {
                    club_name: "Workington AFC",
                    ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc",
                    logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png"
                };

                case "bonnyrigg-rose-fc": return {
                    club_name: "Bonnyrigg Rose FC",
                    ios_url: 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126',
                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose',
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999950/Community-Club/club-logo/bonnyrigg-rose-fc.png"
                }

                case "penrith-afc": return {
                    club_name: "Penrith AFC",
                    ios_url: 'https://apps.apple.com/us/app/penrith-afc/id1639993183',
                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.penrithafc',
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999914/Community-Club/club-logo/penrith-afc.png"
                }

                case "east-kilbride-utd": return {
                    club_name: "East Kilbride Utd",
                    ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451960/Community-Club/club-logo/east-kilbride-utd.png"
                };

                case "sandbach-united-fc": return {
                    club_name: "Sandbach United FC",
                    ios_url: "https://apps.apple.com/gb/app/sandbach-united-fc/id6463419526",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.sandbachunitedfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705998445/Community-Club/club-logo/sandbach-united-fc.png"
                };

                case "keswick-fc": return {
                    club_name: "Keswick FC",
                    ios_url: "https://apps.apple.com/us/app/keswick-fc/id6444525862",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.keswickfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451862/Community-Club/club-logo/keswick-fc.jpg"
                };

                case "giffnock-sc": return {
                    club_name: "Giffnock Sc",
                    ios_url: "https://apps.apple.com/us/app/giffnock-sc/id6448082835",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.giffnocksc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999582/Community-Club/club-logo/giffnock-sc.png"
                };

                case "blackburn-eagles-fc": return {
                    club_name: "Blackburn Eagles FC",
                    ios_url: "https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999827/Community-Club/club-logo/blackburn-eagles-fc.png"
                };
                default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
            }
        }
        data = project()
    } else {
        const project = () => {
            switch (props.match.params.clubidentifier) {

                case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-roversfc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };
                case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                case "tranentcolts-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/lka0ibxfpc0pd3ripugg.png" };
                case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };

                case "workington-afc": return {
                    club_name: "Workington AFC",
                    ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc",
                    logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png"
                };

                case "bonnyrigg-rose-fc": return {
                    club_name: "Bonnyrigg Rose FC",
                    ios_url: 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126',
                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose',
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999950/Community-Club/club-logo/bonnyrigg-rose-fc.png"
                }

                case "penrith-afc": return {
                    club_name: "Penrith AFC",
                    ios_url: 'https://apps.apple.com/us/app/penrith-afc/id1639993183',
                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.penrithafc',
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999914/Community-Club/club-logo/penrith-afc.png"
                }

                case "east-kilbride-utd": return {
                    club_name: "East Kilbride Utd",
                    ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451960/Community-Club/club-logo/east-kilbride-utd.png"
                };

                case "sandbach-united-fc": return {
                    club_name: "Sandbach United FC",
                    ios_url: "https://apps.apple.com/gb/app/sandbach-united-fc/id6463419526",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.sandbachunitedfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705998445/Community-Club/club-logo/sandbach-united-fc.png"
                };

                case "keswick-fc": return {
                    club_name: "Keswick FC",
                    ios_url: "https://apps.apple.com/us/app/keswick-fc/id6444525862",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.keswickfc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451862/Community-Club/club-logo/keswick-fc.jpg"
                };

                case "giffnock-sc": return {
                    club_name: "Giffnock Sc",
                    ios_url: "https://apps.apple.com/us/app/giffnock-sc/id6448082835",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.giffnocksc",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999582/Community-Club/club-logo/giffnock-sc.png"
                };

                case "blackburn-eagles-fc": return {
                    club_name: "Blackburn Eagles FC",
                    ios_url: "https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956",
                    android_url: "https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1",
                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999827/Community-Club/club-logo/blackburn-eagles-fc.png"
                };
                default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
            }
        }
        data = project()
        switch (props.match.params.clubidentifier) {
            case "grenta-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.gretnafc";
            case "greystone-rovers": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/greystone-rovers/id1574600729" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.greystone";
            case "threave-roversfc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/threave-roversfc/id1582757107" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc";
            case "newtongrange-star": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/newtongrange-star/id1586115189" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.newtongrange";
            case "spartans-fc-youth": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.spartansfc";
            case "lochryan-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.lochryanboys";
            case "hutchison-vale-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc";
            case "haddington-athletic-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/haddington-athletic/id1608340052" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc";
            case "northbank-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/northbank-fc/id1610298458" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.northbankfc";
            case "tranentcolts-fc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc";
            case "workington-afc": return isIOS === true ? window.location.href = "https://apps.apple.com/us/app/workington-afc/id1625663209" : window.location.href = "https://play.google.com/store/apps/details?id=com.cc.workingtonafc";
            case "cleator-moor-celtic-fc": return isIOS === true ? window.location.href = 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430' : window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc';
            case "east-kilbride-utd": return isIOS === true ? window.location.href = 'https://apps.apple.com/us/app/itunes-connect/6444095997' : window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.eastkilbride';

            case "sandbach-united-fc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/sandbach-united-fc/id6463419526' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.eastkilbride';

            case "bonnyrigg-rose-fc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose';

            case "penrith-afc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/penrith-afc/id1639993183' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.penrithafc';

            case "keswick-fc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/keswick-fc/id6444525862' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.keswickfc';

            case "giffnock-sc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/giffnock-sc/id6448082835' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.giffnocksc';

            case "blackburn-eagles-fc": return isIOS === true ?
                window.location.href = 'https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956' :
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1';

            default: return ""
        }
    }
    return (
        <div className='nodataFound'>
            <div className="backYellowarea">
            </div>
            <div className="upperLogo">
                <div className="mainImg">
                    <img src={data.logo} alt="txtlogo" fluid="true" />
                </div>
            </div>

            <div className="centerPart">
                <div className="imgSec">
                    < a href={data.android_url}><img src={googleappstore} alt="smallJogging" fluid="true" /></a>
                    < a href={data.ios_url}><img src={appstore} alt="smallJogging" fluid="true" /></a>
                </div>
            </div>


        </div>
    )
}



export default AppLink;