import React from 'react';
import './login.scss';
// import logo from '../../assets/images/logo_yellow.png';
import TextField from '../../components/TextField/TextField'
import CustomButton from '../../components/CustomButton/button';
import ToastComponent from '../../components/ToastComponent/ToastComponent';
import HookForm from '../../components/HookForm/HookForm';
import Constant from "../../util/constant";
import Message from '../../util/message';
import ImageSlider from '../../components/ImageSlider/ImageSlider'
import { Scrollbars } from 'react-custom-scrollbars';
import WebService from "../../util/webService";
import { getCookie } from "../../util/helper";
import pageLoader from '../../assets/images/pageLoader.svg';
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png";
const loginForm = {
  email: {
    name: 'email',
    validate: {
      required: {
        value: true,
        message: ((Message.ErrorMessage.EmailEmpty))
      }, pattern: {
        value: Constant.REGEX.email,
        message: ((Message.ErrorMessage.EmailInvalid))
      }
    },
  },
  password: {
    name: 'password',
    validate: {
      required: {
        value: true,
        message: ((Message.ErrorMessage.PasswordEmpty))
      },
      validate: {
        length: (value) => (value && value.length >= 6) || 'Minimum 6 characters required',
      },

    }
  }
}
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidePassword: false,
      showPass: false,
      isRememberMe: (getCookie("isRememberMe") && getCookie("isRememberMe").length > 0) || false,
      loginFormRememberMe: {
        email: getCookie("email") || "",
        password: getCookie("password") || ""
      },
      toast: {
        show: false,
        type: "",
        msg: "",
        errorToast: false
      },
      width: window.innerWidth, height: window.innerHeight,
      index: 0,
      isLoading: false
    };
  }

  // onKeyDown(e) {
  //   e.keyCode == 13 && this.onFormSubmit()
  // }
  onChangeRememberMe(e) {
    this.setState({
      isRememberMe: e.target.checked,
    });
  }
  createCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }
  eraseCookie(name) {
    this.createCookie(name, "", -1);
  }
  onRememberMe(values) {
    var now = new Date();
    now.setTime(now.getTime() + 1 * 3600 * 1000);//24 hours
    if (this.state.isRememberMe) {
      this.createCookie('email', values.email, 1)
      this.createCookie('password', values.password, 1)
      this.createCookie('isRememberMe', this.state.isRememberMe, 1)
    } else {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++)
        this.eraseCookie(cookies[i].split("=")[0], "", -1);
    }
  }
  onFormSubmit = async (values) => {
    this.setState({ isLoading: true })
    this.onRememberMe(values);
    const param = {
      sEmailId: values.email,
      sPassword: values.password
    };
    //set the default form values on submit
    this.setState({
      loginFormRememberMe: {
        email: values.email,
        password: values.password
      }
    });
    await WebService.postWebServices(
      Constant.API.USER_LOGIN,
      param,
      (response) => {

        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            console.log("Main data=--->", mainData);
            const user = {
              _id: mainData._UserId,
              token: mainData.token,
              sUserRoleId: mainData.sUserRoleId,
              UserName: mainData.UserName,
            };
            localStorage.setItem("KeyUser", JSON.stringify(user));

            localStorage.setItem("isLoggedin", true);
            this.setState({
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false
              }
            });
            setTimeout(() => {
              // this.setState({ isLoading: false })
              this.props.history.push("/Dashboard");
            }, 3000);
          } else {
            this.setState({ isLoading: false })
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true
              }
            });
            setTimeout(() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast
                }
              })
            }, 5000);
          }
        }
      });
    // setTimeout(() => {
    //   this.setState({
    //     toast: {
    //       show: false,
    //       type: this.state.toast.type,
    //       msg: this.state.toast.msg,
    //       errorToast: this.state.toast.errorToast
    //     }
    //   })
    // }, 5000);
  }
  goToForgotPassword = () => {
    this.props.history.push('/forgotpassword');
  }
  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }
  handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  render() {
    const { toast, isRememberMe } = this.state
    return (
      <div className="loginPage">
        <ToastComponent onClose={() => {
          this.setState({
            toast: {
              show: false,
              type: this.state.toast.type,
              msg: this.state.toast.msg,
              errorToast: this.state.toast.errorToast
            }
          })
        }} show={toast.show} toastMessage={toast.msg} toastMessageType={toast.type} errorToast={toast.errorToast} />
        {this.state.isLoading && <div className="wholePageLoader">
          <img src={pageLoader} alt="" />
        </div>}
        <div className='innerPage'>
          <div className="loginleft">
            <Scrollbars>
              <div className="formSection">
                <div className='mainForm'>
                  <HookForm
                    defaultForm={this.state.loginFormRememberMe}
                    onSubmit={(formMethod) => this.onFormSubmit(formMethod)}>
                    {(formMethod) => {
                      return (
                        <div>
                          <div className='logo'>
                            <div className='logoMain'>
                              <img src={logo} alt="" />
                            </div>
                            {/* <p className="logoTxt">COMMUNITY CLUB INITIATIVES</p> */}
                          </div>
                          <div className="bigTxtsec">
                            <span>Securing facilities to
                            the football club &
                            local groups </span>
                            <p className='titleText'>Welcome Back, please login to your account</p>
                          </div>

                          <TextField
                            labelName="EMAIL"
                            formMethod={formMethod}
                            register={formMethod?.register(loginForm.email.validate)}
                            name={loginForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="johndoe@gmail.com"
                          />
                          <TextField
                            labelName="PASSWORD"
                            formMethod={formMethod}
                            register={formMethod?.register(loginForm.password.validate)}
                            // onChange={(e) => { this.setState({ loginFormRememberMe: { email: loginFormRememberMe.email, password: e.target.value } }) }}
                            name={loginForm.password.name}
                            errors={formMethod?.errors}
                            type={this.state.showPass ? "text" : "password"}
                            showHidePassword={() => this.showHidePassword()}
                            placeholder="Password"
                            onKeyDown={this.handleKeyDown}
                            iconClass={!this.state.showPass ? "icon-eye_notvisible" : "icon-eye_visible"}
                            onIconClick={() => {
                              this.setState({ showPass: !this.state.showPass })
                            }}
                          />
                          <div className='checkbox-Forgot'>
                            <div className="checkBox">
                              <input type="checkbox" id="RememberMe"
                                checked={Boolean(isRememberMe)}
                                onChange={this.onChangeRememberMe.bind(this)}
                              />
                              <label htmlFor="RememberMe">Remember Me</label>
                            </div>
                            <div className='forgotLink'>
                              <a onClick={this.goToForgotPassword}>Forgot Password?</a>
                            </div>
                          </div>
                          <div className="btnSection">
                            <CustomButton type="submit"
                              title="Sign In" disabled={!formMethod?.formState.isValid} />
                          </div>

                        </div>
                      )
                    }}
                  </HookForm>
                  {/* <div className="bottomLink">
                    <p>Don't have an account yet?
                      <a className="blueLink"> Register here</a>
                    </p>
                  </div> */}
                </div>
              </div>
            </Scrollbars>
          </div>
          <div className='loginright'>
            <div className='imageSection'>
              <ImageSlider />
            </div>
          </div>

        </div >

      </div >
    )
  }
}

export default (Login);

